
.cw-get-started-app-card {
  width: 352px;
  max-height: 68px;
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: white;
  flex: 0;
  cursor: default;
  padding-bottom: 10px;
}

.cw-card-opened {
  height: auto;
  transition: all 0.10s ease-in;
  max-height: 218px;
  flex: 1;
}

.cw-get-started-app-card-container {
  width: 352px;
  height: 68px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 8px;
  cursor: pointer;
}

.cw-get-started-app-card-icon {
  // margin-right: 10px;
  width: fit-content;
}

.cw-get-started-app-card-name {
  width: 120px;
  // margin-right: 10px;
}

.cw-get-started-app-card-percentage {
  width: 120px;
  margin-right: 15px;
}

.cw-get-started-app-card-arrow {
  width: fit-content;
  margin: 0 10px;
}

.cw-get-started-single-requirement {
  width: 352px;
  margin: 0 0 12px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.syt-antd-progress-circle .syt-antd-progress-text {
  display: none;
}

.syt-antd-drawer-body::-webkit-scrollbar {
  display: none;
}

.cw-app-progress-text {
  color: black !important;
}

.cw-get-started-content .syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-content .syt-antd-skeleton-title, .syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li {
  background: linear-gradient(
          90deg,
          rgba(190, 190, 190, 0.2) 25%,
          rgba(129, 129, 129, 0.24) 37%,
          rgba(190, 190, 190, 0.2) 63%) !important;
  background-size: 400% 100% !important;
  animation: assistant-skeleton-loading 1.4s ease infinite !important;
}

@keyframes assistant-skeleton-loading {
  0% {
    background-position: 100% 50% !important;
  }
  100% {
    background-position: 0 50% !important;
  }
}

.cw-get-started-app-card-percentage .syt-antd-progress-text {
  color: #2F3031 !important;
}

.cw-get-started-content .cwsTypography {
  line-height: 22px;
}
