/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@import url("https://use.typekit.net/yhy1vco.css");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap&.css");
@import '../../lib/components/styles/~syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.css';
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
/* --- Main Colors -- */
/* --- Miscellaneous Colors -- */
/* --- CWS Colors -- */
* {
  font-family: 'Noto Sans';
}
.text-default {
  color: #2f3031;
}
.bold {
  color: #2f3031;
}
/* Override Ant Design variables */
.ant-result-success .ant-result-icon > .anticon {
  color: #14803c;
}
.syt-antd-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: width 0s ease 0.3s, height 0s ease 0.3s;
}
.syt-antd-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.syt-antd-drawer .syt-antd-drawer-content {
  width: 100%;
  height: 100%;
}
.syt-antd-drawer-left,
.syt-antd-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.syt-antd-drawer-left .syt-antd-drawer-content-wrapper,
.syt-antd-drawer-right .syt-antd-drawer-content-wrapper {
  height: 100%;
}
.syt-antd-drawer-left.syt-antd-drawer-open,
.syt-antd-drawer-right.syt-antd-drawer-open {
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.syt-antd-drawer-left {
  left: 0;
}
.syt-antd-drawer-left .syt-antd-drawer-content-wrapper {
  left: 0;
}
.syt-antd-drawer-left.syt-antd-drawer-open .syt-antd-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.syt-antd-drawer-right {
  right: 0;
}
.syt-antd-drawer-right .syt-antd-drawer-content-wrapper {
  right: 0;
}
.syt-antd-drawer-right.syt-antd-drawer-open .syt-antd-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.syt-antd-drawer-right.syt-antd-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.syt-antd-drawer-top,
.syt-antd-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.syt-antd-drawer-top .syt-antd-drawer-content-wrapper,
.syt-antd-drawer-bottom .syt-antd-drawer-content-wrapper {
  width: 100%;
}
.syt-antd-drawer-top.syt-antd-drawer-open,
.syt-antd-drawer-bottom.syt-antd-drawer-open {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.syt-antd-drawer-top {
  top: 0;
}
.syt-antd-drawer-top.syt-antd-drawer-open .syt-antd-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.syt-antd-drawer-bottom {
  bottom: 0;
}
.syt-antd-drawer-bottom .syt-antd-drawer-content-wrapper {
  bottom: 0;
}
.syt-antd-drawer-bottom.syt-antd-drawer-open .syt-antd-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.syt-antd-drawer-bottom.syt-antd-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.syt-antd-drawer.syt-antd-drawer-open .syt-antd-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  pointer-events: auto;
}
.syt-antd-drawer-title {
  flex: 1;
  margin: 0;
  color: #2f3031;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.syt-antd-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.syt-antd-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: #c1c5c8;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.syt-antd-drawer-close:focus,
.syt-antd-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.syt-antd-drawer-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  color: #2f3031;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.syt-antd-drawer-header-title {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.syt-antd-drawer-header-close-only {
  padding-bottom: 0;
  border: none;
}
.syt-antd-drawer-wrapper-body {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
}
.syt-antd-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.syt-antd-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}
.syt-antd-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.syt-antd-drawer .syt-antd-picker-clear {
  background: #fff;
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.syt-antd-drawer-rtl {
  direction: rtl;
}
.syt-antd-drawer-rtl .syt-antd-drawer-close {
  margin-right: 0;
  margin-left: 12px;
}
.syt-antd-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1070;
  display: block;
  width: max-content;
  max-width: 250px;
  visibility: visible;
}
.syt-antd-tooltip-hidden {
  display: none;
}
.syt-antd-tooltip-placement-top,
.syt-antd-tooltip-placement-topLeft,
.syt-antd-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.syt-antd-tooltip-placement-right,
.syt-antd-tooltip-placement-rightTop,
.syt-antd-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.syt-antd-tooltip-placement-bottom,
.syt-antd-tooltip-placement-bottomLeft,
.syt-antd-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.syt-antd-tooltip-placement-left,
.syt-antd-tooltip-placement-leftTop,
.syt-antd-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.syt-antd-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: 0px 6px 16px rgba(71, 71, 71, 0.25);
}
.syt-antd-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.syt-antd-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto;
}
.syt-antd-tooltip-placement-top .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-topLeft .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-topRight .syt-antd-tooltip-arrow {
  bottom: -5.07106781px;
}
.syt-antd-tooltip-placement-top .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-topLeft .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-topRight .syt-antd-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-6.53553391px) rotate(45deg);
}
.syt-antd-tooltip-placement-top .syt-antd-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.syt-antd-tooltip-placement-topLeft .syt-antd-tooltip-arrow {
  left: 13px;
}
.syt-antd-tooltip-placement-topRight .syt-antd-tooltip-arrow {
  right: 13px;
}
.syt-antd-tooltip-placement-right .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-rightTop .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-rightBottom .syt-antd-tooltip-arrow {
  left: -5.07106781px;
}
.syt-antd-tooltip-placement-right .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-rightTop .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-rightBottom .syt-antd-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(6.53553391px) rotate(45deg);
}
.syt-antd-tooltip-placement-right .syt-antd-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.syt-antd-tooltip-placement-rightTop .syt-antd-tooltip-arrow {
  top: 5px;
}
.syt-antd-tooltip-placement-rightBottom .syt-antd-tooltip-arrow {
  bottom: 5px;
}
.syt-antd-tooltip-placement-left .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-leftTop .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-leftBottom .syt-antd-tooltip-arrow {
  right: -5.07106781px;
}
.syt-antd-tooltip-placement-left .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-leftTop .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-leftBottom .syt-antd-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-6.53553391px) rotate(45deg);
}
.syt-antd-tooltip-placement-left .syt-antd-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.syt-antd-tooltip-placement-leftTop .syt-antd-tooltip-arrow {
  top: 5px;
}
.syt-antd-tooltip-placement-leftBottom .syt-antd-tooltip-arrow {
  bottom: 5px;
}
.syt-antd-tooltip-placement-bottom .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-bottomLeft .syt-antd-tooltip-arrow,
.syt-antd-tooltip-placement-bottomRight .syt-antd-tooltip-arrow {
  top: -5.07106781px;
}
.syt-antd-tooltip-placement-bottom .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-bottomLeft .syt-antd-tooltip-arrow-content,
.syt-antd-tooltip-placement-bottomRight .syt-antd-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(6.53553391px) rotate(45deg);
}
.syt-antd-tooltip-placement-bottom .syt-antd-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.syt-antd-tooltip-placement-bottomLeft .syt-antd-tooltip-arrow {
  left: 13px;
}
.syt-antd-tooltip-placement-bottomRight .syt-antd-tooltip-arrow {
  right: 13px;
}
.syt-antd-tooltip-pink .syt-antd-tooltip-inner {
  background-color: #eb2f96;
}
.syt-antd-tooltip-pink .syt-antd-tooltip-arrow-content {
  background-color: #eb2f96;
}
.syt-antd-tooltip-magenta .syt-antd-tooltip-inner {
  background-color: #eb2f96;
}
.syt-antd-tooltip-magenta .syt-antd-tooltip-arrow-content {
  background-color: #eb2f96;
}
.syt-antd-tooltip-red .syt-antd-tooltip-inner {
  background-color: #f5222d;
}
.syt-antd-tooltip-red .syt-antd-tooltip-arrow-content {
  background-color: #f5222d;
}
.syt-antd-tooltip-volcano .syt-antd-tooltip-inner {
  background-color: #fa541c;
}
.syt-antd-tooltip-volcano .syt-antd-tooltip-arrow-content {
  background-color: #fa541c;
}
.syt-antd-tooltip-orange .syt-antd-tooltip-inner {
  background-color: #fa8c16;
}
.syt-antd-tooltip-orange .syt-antd-tooltip-arrow-content {
  background-color: #fa8c16;
}
.syt-antd-tooltip-yellow .syt-antd-tooltip-inner {
  background-color: #fadb14;
}
.syt-antd-tooltip-yellow .syt-antd-tooltip-arrow-content {
  background-color: #fadb14;
}
.syt-antd-tooltip-gold .syt-antd-tooltip-inner {
  background-color: #faad14;
}
.syt-antd-tooltip-gold .syt-antd-tooltip-arrow-content {
  background-color: #faad14;
}
.syt-antd-tooltip-cyan .syt-antd-tooltip-inner {
  background-color: #13c2c2;
}
.syt-antd-tooltip-cyan .syt-antd-tooltip-arrow-content {
  background-color: #13c2c2;
}
.syt-antd-tooltip-lime .syt-antd-tooltip-inner {
  background-color: #a0d911;
}
.syt-antd-tooltip-lime .syt-antd-tooltip-arrow-content {
  background-color: #a0d911;
}
.syt-antd-tooltip-green .syt-antd-tooltip-inner {
  background-color: #52c41a;
}
.syt-antd-tooltip-green .syt-antd-tooltip-arrow-content {
  background-color: #52c41a;
}
.syt-antd-tooltip-blue .syt-antd-tooltip-inner {
  background-color: #1890ff;
}
.syt-antd-tooltip-blue .syt-antd-tooltip-arrow-content {
  background-color: #1890ff;
}
.syt-antd-tooltip-geekblue .syt-antd-tooltip-inner {
  background-color: #2f54eb;
}
.syt-antd-tooltip-geekblue .syt-antd-tooltip-arrow-content {
  background-color: #2f54eb;
}
.syt-antd-tooltip-purple .syt-antd-tooltip-inner {
  background-color: #722ed1;
}
.syt-antd-tooltip-purple .syt-antd-tooltip-arrow-content {
  background-color: #722ed1;
}
.syt-antd-tooltip-rtl {
  direction: rtl;
}
.syt-antd-tooltip-rtl .syt-antd-tooltip-inner {
  text-align: right;
}
.syt-antd-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.syt-antd-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.syt-antd-progress-steps {
  display: inline-block;
}
.syt-antd-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.syt-antd-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.syt-antd-progress-steps-item-active {
  background: #1890ff;
}
.syt-antd-progress-small.syt-antd-progress-line,
.syt-antd-progress-small.syt-antd-progress-line .syt-antd-progress-text .anticon {
  font-size: 12px;
}
.syt-antd-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.syt-antd-progress-show-info .syt-antd-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.syt-antd-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.syt-antd-progress-circle-trail {
  stroke: #f5f5f5;
}
.syt-antd-progress-circle-path {
  animation: syt-antd-progress-appear 0.3s;
}
.syt-antd-progress-inner:not(.syt-antd-progress-circle-gradient) .syt-antd-progress-circle-path {
  stroke: #1890ff;
}
.syt-antd-progress-success-bg,
.syt-antd-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.syt-antd-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #aab400;
}
.syt-antd-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: #2f3031;
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.syt-antd-progress-text .anticon {
  font-size: 14px;
}
.syt-antd-progress-status-active .syt-antd-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: syt-antd-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.syt-antd-progress-status-exception .syt-antd-progress-bg {
  background-color: #CF3537;
}
.syt-antd-progress-status-exception .syt-antd-progress-text {
  color: #CF3537;
}
.syt-antd-progress-status-exception .syt-antd-progress-inner:not(.syt-antd-progress-circle-gradient) .syt-antd-progress-circle-path {
  stroke: #CF3537;
}
.syt-antd-progress-status-success .syt-antd-progress-bg {
  background-color: #aab400;
}
.syt-antd-progress-status-success .syt-antd-progress-text {
  color: #aab400;
}
.syt-antd-progress-status-success .syt-antd-progress-inner:not(.syt-antd-progress-circle-gradient) .syt-antd-progress-circle-path {
  stroke: #aab400;
}
.syt-antd-progress-circle .syt-antd-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.syt-antd-progress-circle .syt-antd-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.syt-antd-progress-circle .syt-antd-progress-text .anticon {
  font-size: 1.16666667em;
}
.syt-antd-progress-circle.syt-antd-progress-status-exception .syt-antd-progress-text {
  color: #CF3537;
}
.syt-antd-progress-circle.syt-antd-progress-status-success .syt-antd-progress-text {
  color: #aab400;
}
@keyframes syt-antd-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
.syt-antd-progress-rtl {
  direction: rtl;
}
.syt-antd-progress-rtl.syt-antd-progress-show-info .syt-antd-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.syt-antd-progress-rtl .syt-antd-progress-success-bg {
  right: 0;
  left: auto;
}
.syt-antd-progress-rtl.syt-antd-progress-line .syt-antd-progress-text,
.syt-antd-progress-rtl.syt-antd-progress-steps .syt-antd-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.syt-antd-skeleton {
  display: table;
  width: 100%;
}
.syt-antd-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.syt-antd-skeleton-header .syt-antd-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.syt-antd-skeleton-header .syt-antd-skeleton-avatar.syt-antd-skeleton-avatar-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-header .syt-antd-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.syt-antd-skeleton-header .syt-antd-skeleton-avatar-lg.syt-antd-skeleton-avatar-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-header .syt-antd-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.syt-antd-skeleton-header .syt-antd-skeleton-avatar-sm.syt-antd-skeleton-avatar-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.syt-antd-skeleton-content .syt-antd-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}
.syt-antd-skeleton-content .syt-antd-skeleton-title + .syt-antd-skeleton-paragraph {
  margin-top: 24px;
}
.syt-antd-skeleton-content .syt-antd-skeleton-paragraph {
  padding: 0;
}
.syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}
.syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.syt-antd-skeleton-with-avatar .syt-antd-skeleton-content .syt-antd-skeleton-title {
  margin-top: 12px;
}
.syt-antd-skeleton-with-avatar .syt-antd-skeleton-content .syt-antd-skeleton-title + .syt-antd-skeleton-paragraph {
  margin-top: 28px;
}
.syt-antd-skeleton-round .syt-antd-skeleton-content .syt-antd-skeleton-title,
.syt-antd-skeleton-round .syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li {
  border-radius: 100px;
}
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-content .syt-antd-skeleton-title,
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  animation: syt-antd-skeleton-loading 1.4s ease infinite;
}
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-avatar {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  animation: syt-antd-skeleton-loading 1.4s ease infinite;
}
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-button {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  animation: syt-antd-skeleton-loading 1.4s ease infinite;
}
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-input {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  animation: syt-antd-skeleton-loading 1.4s ease infinite;
}
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-image {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  animation: syt-antd-skeleton-loading 1.4s ease infinite;
}
.syt-antd-skeleton.syt-antd-skeleton-block {
  width: 100%;
}
.syt-antd-skeleton.syt-antd-skeleton-block .syt-antd-skeleton-button {
  width: 100%;
}
.syt-antd-skeleton-element {
  display: inline-block;
  width: auto;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button.syt-antd-skeleton-button-circle {
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button.syt-antd-skeleton-button-round {
  border-radius: 32px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button-lg.syt-antd-skeleton-button-circle {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button-lg.syt-antd-skeleton-button-round {
  border-radius: 40px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button-sm.syt-antd-skeleton-button-circle {
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-button-sm.syt-antd-skeleton-button-round {
  border-radius: 24px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-avatar.syt-antd-skeleton-avatar-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-avatar-lg.syt-antd-skeleton-avatar-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-avatar-sm.syt-antd-skeleton-avatar-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 100%;
  height: 32px;
  line-height: 32px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-input-lg {
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-input-sm {
  width: 100%;
  height: 24px;
  line-height: 24px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-image.syt-antd-skeleton-image-circle {
  border-radius: 50%;
}
.syt-antd-skeleton-element .syt-antd-skeleton-image-path {
  fill: #bfbfbf;
}
.syt-antd-skeleton-element .syt-antd-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.syt-antd-skeleton-element .syt-antd-skeleton-image-svg.syt-antd-skeleton-image-circle {
  border-radius: 50%;
}
@keyframes syt-antd-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.syt-antd-skeleton-rtl {
  direction: rtl;
}
.syt-antd-skeleton-rtl .syt-antd-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.syt-antd-skeleton-rtl.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-content .syt-antd-skeleton-title,
.syt-antd-skeleton-rtl.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li {
  animation-name: syt-antd-skeleton-loading-rtl;
}
.syt-antd-skeleton-rtl.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-avatar {
  animation-name: syt-antd-skeleton-loading-rtl;
}
@keyframes syt-antd-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.syt-antd-drawer-header {
  padding: 24px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0075em;
  border-bottom: none;
}
.syt-antd-drawer-body {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
}
.syt-antd-drawer-footer {
  padding: 16px 24px;
  border-top: none;
}
.cw-get-started-text {
  margin-bottom: 10px;
}
.cw-get-started-header .cwsTypography {
  line-height: 22px;
}
.cw-get-started-header {
  width: fit-content;
}
.cw-get-started-content {
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
}
.cw-get-started-empty-content {
  margin-top: 150px;
  text-align: center;
}
.cw-get-started-button {
  background: #5EBB7F;
  width: 352px;
  height: 105px;
  align-items: flex-start;
  padding-left: 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin-top: 17px;
}
.cw-get-started-button:hover {
  background: #5cb27a;
}
.cw-get-started-grower-icon {
  margin-top: -122px;
  float: right;
  margin-right: 20px;
}
.cw-get-started-wheat-icon {
  margin-top: -47px;
  float: right;
  margin-left: 80px;
}
.cw-get-whats-new-content {
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.cw-quick-link-button {
  width: 100%;
  background: #FFFFFF;
  text-align: left;
  color: #0071CD;
}
.cw-quick-link-button:hover {
  background: #FFFFFF;
  color: #0071CD;
  text-decoration: underline;
}
.cw-whats-new-button {
  background: #FFFFFF;
  width: 352px;
  height: 105px;
  align-items: flex-start;
  padding-left: 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin-top: 35px;
}
.cw-whats-new-button:hover {
  background: #F3F4F6;
}
.cw-whats-new-button .cw-whats-new-text {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #363948;
  margin-top: 60px;
}
.cw-whats-new-girl-icon {
  margin-top: -122px;
  float: right;
  margin-right: 20px;
  position: relative;
  z-index: 2;
}
.cw-whats-new-grama-icon {
  margin-top: -47px;
  float: right;
  margin-left: 80px;
  position: absolute;
  right: 0px;
  bottom: -5px;
  z-index: 0;
}
.cw-get-started-app-card {
  width: 352px;
  max-height: 68px;
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: white;
  flex: 0;
  cursor: default;
  padding-bottom: 10px;
}
.cw-card-opened {
  height: auto;
  transition: all 0.1s ease-in;
  max-height: 218px;
  flex: 1;
}
.cw-get-started-app-card-container {
  width: 352px;
  height: 68px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 8px;
  cursor: pointer;
}
.cw-get-started-app-card-icon {
  width: fit-content;
}
.cw-get-started-app-card-name {
  width: 120px;
}
.cw-get-started-app-card-percentage {
  width: 120px;
  margin-right: 15px;
}
.cw-get-started-app-card-arrow {
  width: fit-content;
  margin: 0 10px;
}
.cw-get-started-single-requirement {
  width: 352px;
  margin: 0 0 12px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.syt-antd-progress-circle .syt-antd-progress-text {
  display: none;
}
.syt-antd-drawer-body::-webkit-scrollbar {
  display: none;
}
.cw-app-progress-text {
  color: black !important;
}
.cw-get-started-content .syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-content .syt-antd-skeleton-title,
.syt-antd-skeleton.syt-antd-skeleton-active .syt-antd-skeleton-content .syt-antd-skeleton-paragraph > li {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%) !important;
  background-size: 400% 100% !important;
  animation: assistant-skeleton-loading 1.4s ease infinite !important;
}
@keyframes assistant-skeleton-loading {
  0% {
    background-position: 100% 50% !important;
  }
  100% {
    background-position: 0 50% !important;
  }
}
.cw-get-started-app-card-percentage .syt-antd-progress-text {
  color: #2F3031 !important;
}
.cw-get-started-content .cwsTypography {
  line-height: 22px;
}
.loader {
  border: 4px solid #DFE2E7;
  border-radius: 50%;
  border-top: 4px solid #14803C;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1.5s linear infinite;
  /* Safari */
  animation: spin 1.5s linear infinite;
}
.loader-wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 45%;
  z-index: 9999999;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cw-assistant-button {
  cursor: pointer;
}
.cw-assistant-button-parent {
  height: 40px;
  width: 50px;
  align-items: center;
}
.cw-pulsating-button {
  content: '';
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  left: 6.5%;
  top: 5%;
  box-sizing: border-box;
  border-radius: 5px;
  background: linear-gradient(111.8deg, #00004B -2.46%, #73DC78 120.54%);
  animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  z-index: 0;
}
.cw-assistant-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px;
  left: 15%;
  top: 15%;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #F3F4F6;
  border-radius: 4px;
  border-width: 1px;
  line-height: normal;
}
.cw-assistant-icon:hover {
  border-color: #d9d9d9;
  border-style: solid;
  background: linear-gradient(111.8deg, #00004B -2.46%, #73DC78 120.54%);
}
.cw-assistant-icon:hover .tooltip {
  visibility: visible;
}
.cw-assistant-icon:hover svg {
  fill: white;
}
.cw-assistant-icon .cw-assistant-leaf-icon {
  position: absolute;
  display: flex;
  padding-top: 4px;
  padding-right: 2px ;
  padding-left: 2px;
}
.cw-assistant-icon .cw-assistant-arrows {
  position: absolute;
  display: flex;
  padding-left: 1px;
}
.cw-assistant-icon-active {
  background: linear-gradient(111.8deg, #00004B -2.46%, #73DC78 120.54%);
}
.cw-assistant-icon-active svg {
  fill: white;
}
@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0.2;
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
.cw-assistant-popover {
  width: 280px;
  height: 160px;
}
.cw-assistant-popover-buttons {
  margin-top: 65px;
  display: flex;
  justify-content: flex-end;
}
.outlineButton {
  color: white !important;
  background-color: transparent !important;
}
.cw-popover-assistant .ant-popover-inner-content {
  background-color: #232630;
  padding: 12px 16px;
}
.cw-popover-assistant .ant-popover-arrow {
  border-top-color: #232630 !important;
  border-right-color: #232630 !important;
}
.syt-antd-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.syt-antd-modal.syt-antd-zoom-enter,
.syt-antd-modal.syt-antdzoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
.syt-antd-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.syt-antd-modal-mask-hidden {
  display: none;
}
.syt-antd-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.syt-antd-modal-wrap {
  z-index: 1000;
}
.syt-antd-modal-title {
  margin: 0;
  color: #2f3031;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.syt-antd-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.syt-antd-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: #c1c5c8;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.syt-antd-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.syt-antd-modal-close:focus,
.syt-antd-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.syt-antd-modal-header {
  padding: 16px 24px;
  color: #2f3031;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.syt-antd-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.syt-antd-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 4px 4px;
}
.syt-antd-modal-footer .syt-antd-btn + .syt-antd-btn:not(.syt-antd-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}
.syt-antd-modal-open {
  overflow: hidden;
}
.syt-antd-modal-centered {
  text-align: center;
}
.syt-antd-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.syt-antd-modal-centered .syt-antd-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .syt-antd-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .syt-antd-modal-centered .syt-antd-modal {
    flex: 1;
  }
}
.syt-antd-modal-confirm .syt-antd-modal-header {
  display: none;
}
.syt-antd-modal-confirm .syt-antd-modal-body {
  padding: 32px 32px 24px;
}
.syt-antd-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.syt-antd-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.syt-antd-modal-confirm-body .syt-antd-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: #2f3031;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.syt-antd-modal-confirm-body .syt-antd-modal-confirm-content {
  margin-top: 8px;
  color: #2f3031;
  font-size: 14px;
}
.syt-antd-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.syt-antd-modal-confirm-body > .anticon + .syt-antd-modal-confirm-title + .syt-antd-modal-confirm-content {
  margin-left: 38px;
}
.syt-antd-modal-confirm .syt-antd-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.syt-antd-modal-confirm .syt-antd-modal-confirm-btns .syt-antd-btn + .syt-antd-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.syt-antd-modal-confirm-error .syt-antd-modal-confirm-body > .anticon {
  color: #CF3537;
}
.syt-antd-modal-confirm-warning .syt-antd-modal-confirm-body > .anticon,
.syt-antd-modal-confirm-confirm .syt-antd-modal-confirm-body > .anticon {
  color: #ffca41;
}
.syt-antd-modal-confirm-info .syt-antd-modal-confirm-body > .anticon {
  color: #0071CD;
}
.syt-antd-modal-confirm-success .syt-antd-modal-confirm-body > .anticon {
  color: #aab400;
}
.syt-antd-modal-wrap-rtl {
  direction: rtl;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-close {
  right: initial;
  left: 0;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-footer {
  text-align: left;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-footer .syt-antd-btn + .syt-antd-btn {
  margin-right: 8px;
  margin-left: 0;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-confirm-body {
  direction: rtl;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-confirm-body > .anticon + .syt-antd-modal-confirm-title + .syt-antd-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-confirm-btns {
  float: left;
}
.syt-antd-modal-wrap-rtl .syt-antd-modal-confirm-btns .syt-antd-btn + .syt-antd-btn {
  margin-right: 8px;
  margin-left: 0;
}
.syt-antd-modal-wrap-rtl.syt-antd-modal-centered .syt-antd-modal {
  text-align: right;
}
.whats-new-modal-wrap .syt-antd-modal-content {
  padding-bottom: 15px;
}
.whats-new-modal-wrap .syt-antd-modal-body {
  padding: 32px 40px 0px 32px;
  overflow: auto;
  height: calc(100vh - 250px);
  margin-right: 4px;
}
.whats-new-modal-wrap .slick-list {
  border-radius: 8px;
}
.whats-new-modal-wrap .syt-antd-modal-header {
  color: #ffffff;
  background: linear-gradient(95.48deg, #00004b 29.47%, #369f61 98.82%);
  height: 72px;
  padding: 22px 30px 22px 25px;
}
.whats-new-modal-wrap .syt-antd-modal-content {
  border-radius: 8px;
}
.whats-new-modal-wrap .syt-antd-modal-content .syt-antd-modal-header {
  border-radius: 8px 8px 0px 0px;
}
.whats-new-modal-wrap .title-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.0075em;
  color: #ffffff;
  display: inline-block;
  margin-left: 17px;
}
.whats-new-modal-wrap .syt-antd-modal-title svg {
  margin-bottom: -4px;
}
.whats-new-modal-wrap .syt-antd-modal-close {
  top: 7px;
  right: 6px;
  color: #ffffff;
}
.whats-new-modal-wrap .syt-antd-modal-footer {
  background: #ffffff;
  box-shadow: inset 0px 1px 0px #c2c7d0;
  padding: 16px 40px;
}
.whats-new-modal-wrap .syt-antd-modal-footer .footer-text {
  color: #14803c;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.whats-new-modal-wrap .modal-content {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.0075em;
  color: #4d5165;
}
.whats-new-modal-wrap .modal-content .item-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #14151c;
  margin-bottom: 4px;
}
.whats-new-modal-wrap .modal-content .item-date {
  color: #868ca2;
  margin-bottom: 15px;
}
.whats-new-modal-wrap .modal-content .item-image {
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
}
.whats-new-modal-wrap .squareicon {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin: 0px 8px;
}
.whats-new-modal-wrap .squareicon span {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  position: absolute;
}
.whats-new-modal-wrap .syt-antd-modal-body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer;
  margin-right: 4px;
}
.whats-new-modal-wrap .syt-antd-modal-body::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 8px;
  box-shadow: inset 0 3px 2px 6px #c2c7d0;
  -webkit-box-shadow: inset 0 3px 2px 6px #c2c7d0;
}
.whats-new-modal-wrap .syt-antd-modal-body::-webkit-scrollbar-track {
  cursor: pointer;
  border-radius: 8px;
  background: none;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid white;
}
.whats-new-modal-wrap .whats-new-modal {
  width: 700px !important;
  margin: 80px 0px;
  padding-bottom: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
