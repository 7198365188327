@import '~antd/lib/modal/style/index';

.whats-new-modal-wrap{
  .syt-antd-modal-content{
    padding-bottom: 15px;
  }
  .syt-antd-modal-body {
    padding: 32px 40px 0px 32px;
    overflow: auto;
    height: calc(100vh - 250px);
    margin-right: 4px;
  }
  .slick-list {
    border-radius: 8px;
  }
  .syt-antd-modal-header {
    color: #ffffff;
    background: linear-gradient(95.48deg, #00004b 29.47%, #369f61 98.82%);
    height: 72px;
    padding: 22px 30px 22px 25px;
  }
  .syt-antd-modal-content {
    border-radius: 8px;
  }
  .syt-antd-modal-content .syt-antd-modal-header {
    border-radius: 8px 8px 0px 0px;
  }
  .title-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.0075em;
    color: #ffffff;
    display: inline-block;
    margin-left: 17px;
  }
  .syt-antd-modal-title {
    svg {
      margin-bottom: -4px;
    }
  }
  .syt-antd-modal-close {
    top: 7px;
    right: 6px;
    color: #ffffff;
  }
  .syt-antd-modal-footer {
    background: #ffffff;
    box-shadow: inset 0px 1px 0px #c2c7d0;
    padding: 16px 40px;
    .footer-text {
      color: #14803c;
      font-family: Noto Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
    }
  }
  .modal-content {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.0075em;
    color: #4d5165;
    .item-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #14151c;
      margin-bottom: 4px;
    }
    .item-date {
      color: #868ca2;
      margin-bottom: 15px;
    }
    .item-image {
      width: 100%;
      height: 100%;
      margin-bottom: 15px;
    }
  }
  .squareicon {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 20px;
    margin: 0px 8px;
    span{
    display: inline-block;
    width: 32px;
    height: 32px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    position: absolute;
    }
    
  }
  .syt-antd-modal-body::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    cursor: pointer;
    margin-right: 4px;
  }
  .syt-antd-modal-body::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 8px;
    box-shadow: inset 0 3px 2px 6px #c2c7d0;
    -webkit-box-shadow: inset 0 3px 2px 6px #c2c7d0;
  }
  .syt-antd-modal-body::-webkit-scrollbar-track {
    cursor: pointer;
    border-radius: 8px;
    background: none;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    border-left: 2px solid white;
  }
}
.whats-new-modal-wrap .whats-new-modal{
  width: 700px !important;
  margin: 80px 0px;
  padding-bottom: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}