
.cw-header {
    margin-bottom: 14px;
}
.cw-single-app-container {
    width: 372px;
    display: flex;
    flex-direction: row;
}
.cw-single-app-selector {
    width: 292px;
    height: 58px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-left: 12px;
    padding-top: 10px;
    margin-right: 4px;
    margin-bottom: 4px;
    &:hover {
        background-color: #F3F4F6;
    }
}
.cw-single-app-single-workspace {
    width: 344px;
}
.cw-single-app-arrow {
    width: 52px;
    height: 58px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-top: 24px;
    &:hover {
        background-color: #F3F4F6;
    }
}
.cw-single-app-icon {
    margin-right: 10px;
    width: fit-content;
    height: 36px;
    background-color: #00004B;
    border-radius: 8px;
}
.cw-deck-of-apps-footer{
    height: 32px;
}
.syt-antd-divider-horizontal {
    margin: 12px 0;
    width: 372px;
}
.syt-antd-popover-inner-content {
    padding: 0;
}

.syt-antd-collapse {
    border: 0
}
.syt-antd-collapse .syt-antd-collapse-item {
    border: 0
}
.syt-antd-collapse-content {
    border-top: 0
}

.syt-antd-collapse-header {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
}

.syt-antd-collapse-content-box {
    padding-left: 0px !important;
}

.cw-single-app-workspace{
    width: 348px;
    height: 32px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-left: 12px;
    padding-top: 8px;
    margin-right: 4px;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    overflow:hidden;
    white-space:nowrap;
    &:hover {
        background-color: #F3F4F6;
    }
}

.cw-single-app-subtitle{
    text-overflow: ellipsis;
    overflow:hidden;
    white-space:nowrap;
    width: 220px;
}

.cw-single-app-title{
   flex-direction: column;
    margin-top: 0;
    line-height: 14px;
}

span.cwsTypography, div.cwsTypography{
    line-height: 14px;
}