.cw-assistant-button {
  cursor: pointer;
}
.cw-assistant-button-parent{
  height: 40px;
  width: 50px;
  align-items: center;
}
.cw-pulsating-button {
  content: '';
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  left:6.5%;
  top:5%;
  box-sizing: border-box;
  border-radius: 5px;
  background: linear-gradient(111.8deg, #00004B -2.46%, #73DC78 120.54%);
  animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  z-index: 0;
}

.cw-assistant-icon{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px;
  left: 15%;
  top:15%;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #F3F4F6;
  border-radius: 4px;
  border-width: 1px;
  line-height: normal;
  &:hover {
    border-color: #d9d9d9;
    border-style: solid;
    background: linear-gradient(111.8deg, #00004B -2.46%, #73DC78 120.54%);
    .tooltip{
      visibility: visible;
    }
    svg{
      fill: white;
    }
  }
  .cw-assistant-leaf-icon {
    position: absolute;
    display: flex;
    padding-top: 4px;
    padding-right: 2px ;
    padding-left: 2px;


  }

  .cw-assistant-arrows{
    position: absolute;
    display: flex;
    padding-left: 1px;
  }

}

.cw-assistant-icon-active{
  //border-color: #d9d9d9;
  background: linear-gradient(111.8deg, #00004B -2.46%, #73DC78 120.54%);
  svg{
    fill: white;
  }

}


@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0.2;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.9);
  }
}

.cw-assistant-popover {
  width: 280px;
  height: 160px;
}

.cw-assistant-popover-buttons {
  margin-top: 65px;
  display: flex;
  justify-content: flex-end;
}

.outlineButton {
  color: white !important;
  background-color: transparent !important;
}

.cw-popover-assistant {
  .ant-popover-inner-content {
    background-color: #232630;
    padding: 12px 16px;
  }

  .ant-popover-arrow {
    border-top-color  : #232630 !important;
    border-right-color: #232630 !important;
  }
}
