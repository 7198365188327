/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@import url("https://use.typekit.net/yhy1vco.css");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap&.css");
@import '../../lib/components/styles/~syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.css';
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
/* --- Main Colors -- */
/* --- Miscellaneous Colors -- */
/* --- CWS Colors -- */
* {
  font-family: 'Noto Sans';
}
.text-default {
  color: #2f3031;
}
.bold {
  color: #2f3031;
}
/* Override Ant Design variables */
.ant-result-success .ant-result-icon > .anticon {
  color: #14803c;
}
.loader {
  border: 4px solid #DFE2E7;
  border-radius: 50%;
  border-top: 4px solid #14803C;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1.5s linear infinite;
  /* Safari */
  animation: spin 1.5s linear infinite;
}
.loader-wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 45%;
  z-index: 9999999;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cw-deck-of-apps-wrapper {
  width: 372px;
  padding: 18px 12px 0 12px;
}
.syt-antd-popover-arrow {
  display: none;
}
.syt-antd-popover-content {
  width: 372px;
}
.cw-deck-of-apps-icon {
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
}
.cw-deck-of-apps-icon:hover {
  background-color: #C2C7D0;
}
.cw-dont-have-access-info-modal .syt-antd-modal-body {
  padding: 0px;
  padding-bottom: 26px;
  text-align: center;
}
.cw-dont-have-access-info-modal .syt-antd-modal-body p.small {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #696F88;
  font-weight: 400;
}
.cw-dont-have-access-info-modal .syt-antd-modal-body p.title {
  font-size: 20px;
  color: #14803C;
  font-weight: 600;
}
.cw-dont-have-access-info-modal .syt-antd-modal-body p.regular {
  font-size: 16px;
  color: #14151C;
  font-weight: 400;
}
.cw-dont-have-access-info-modal .syt-antd-modal-body img,
.cw-dont-have-access-info-modal .syt-antd-modal-body svg {
  width: 100%;
}
.cw-collapse-menu {
  border: none;
}
.no-access-animation-block {
  position: relative;
  top: 0;
  left: 0;
}
.no-access-animation-block #access-animation {
  position: relative;
  top: 0;
  left: 0;
}
.cw-header {
  margin-bottom: 14px;
}
.cw-single-app-container {
  width: 372px;
  display: flex;
  flex-direction: row;
}
.cw-single-app-selector {
  width: 292px;
  height: 58px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  padding-top: 10px;
  margin-right: 4px;
  margin-bottom: 4px;
}
.cw-single-app-selector:hover {
  background-color: #F3F4F6;
}
.cw-single-app-single-workspace {
  width: 344px;
}
.cw-single-app-arrow {
  width: 52px;
  height: 58px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-top: 24px;
}
.cw-single-app-arrow:hover {
  background-color: #F3F4F6;
}
.cw-single-app-icon {
  margin-right: 10px;
  width: fit-content;
  height: 36px;
  background-color: #00004B;
  border-radius: 8px;
}
.cw-deck-of-apps-footer {
  height: 32px;
}
.syt-antd-divider-horizontal {
  margin: 12px 0;
  width: 372px;
}
.syt-antd-popover-inner-content {
  padding: 0;
}
.syt-antd-collapse {
  border: 0;
}
.syt-antd-collapse .syt-antd-collapse-item {
  border: 0;
}
.syt-antd-collapse-content {
  border-top: 0;
}
.syt-antd-collapse-header {
  padding-left: 0px !important;
  padding-bottom: 0px !important;
}
.syt-antd-collapse-content-box {
  padding-left: 0px !important;
}
.cw-single-app-workspace {
  width: 348px;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  padding-top: 8px;
  margin-right: 4px;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.cw-single-app-workspace:hover {
  background-color: #F3F4F6;
}
.cw-single-app-subtitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 220px;
}
.cw-single-app-title {
  flex-direction: column;
  margin-top: 0;
  line-height: 14px;
}
span.cwsTypography,
div.cwsTypography {
  line-height: 14px;
}
.syt-antd-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #2f3031;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #0092e4;
  border-bottom: 0;
  border-radius: 4px;
}
.syt-antd-collapse > .syt-antd-collapse-item {
  border-bottom: 1px solid #0092e4;
}
.syt-antd-collapse > .syt-antd-collapse-item:last-child,
.syt-antd-collapse > .syt-antd-collapse-item:last-child > .syt-antd-collapse-header {
  border-radius: 0 0 4px 4px;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: #2f3031;
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  vertical-align: -1px;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow svg {
  transition: transform 0.24s;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-extra {
  margin-left: auto;
}
.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header:focus {
  outline: none;
}
.syt-antd-collapse > .syt-antd-collapse-item .syt-antd-collapse-header-collapsible-only {
  cursor: default;
}
.syt-antd-collapse > .syt-antd-collapse-item .syt-antd-collapse-header-collapsible-only .syt-antd-collapse-header-text {
  cursor: pointer;
}
.syt-antd-collapse > .syt-antd-collapse-item.syt-antd-collapse-no-arrow > .syt-antd-collapse-header {
  padding-left: 12px;
}
.syt-antd-collapse-icon-position-right > .syt-antd-collapse-item > .syt-antd-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-right: 40px;
}
.syt-antd-collapse-icon-position-right > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
}
.syt-antd-collapse-content {
  color: #2f3031;
  background-color: #fff;
  border-top: 1px solid #0092e4;
}
.syt-antd-collapse-content > .syt-antd-collapse-content-box {
  padding: 16px;
}
.syt-antd-collapse-content-hidden {
  display: none;
}
.syt-antd-collapse-item:last-child > .syt-antd-collapse-content {
  border-radius: 0 0 4px 4px;
}
.syt-antd-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.syt-antd-collapse-borderless > .syt-antd-collapse-item {
  border-bottom: 1px solid #0092e4;
}
.syt-antd-collapse-borderless > .syt-antd-collapse-item:last-child,
.syt-antd-collapse-borderless > .syt-antd-collapse-item:last-child .syt-antd-collapse-header {
  border-radius: 0;
}
.syt-antd-collapse-borderless > .syt-antd-collapse-item > .syt-antd-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.syt-antd-collapse-borderless > .syt-antd-collapse-item > .syt-antd-collapse-content > .syt-antd-collapse-content-box {
  padding-top: 4px;
}
.syt-antd-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.syt-antd-collapse-ghost > .syt-antd-collapse-item {
  border-bottom: 0;
}
.syt-antd-collapse-ghost > .syt-antd-collapse-item > .syt-antd-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.syt-antd-collapse-ghost > .syt-antd-collapse-item > .syt-antd-collapse-content > .syt-antd-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.syt-antd-collapse .syt-antd-collapse-item-disabled > .syt-antd-collapse-header,
.syt-antd-collapse .syt-antd-collapse-item-disabled > .syt-antd-collapse-header > .arrow {
  color: #c1c5c8;
  cursor: not-allowed;
}
.syt-antd-collapse-rtl {
  direction: rtl;
}
.syt-antd-collapse-rtl .syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.syt-antd-collapse-rtl.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow {
  margin-right: 0;
  margin-left: 12px;
}
.syt-antd-collapse-rtl.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-arrow svg {
  transform: rotate(180deg);
}
.syt-antd-collapse-rtl.syt-antd-collapse > .syt-antd-collapse-item > .syt-antd-collapse-header .syt-antd-collapse-extra {
  margin-right: auto;
  margin-left: 0;
}
.syt-antd-collapse-rtl.syt-antd-collapse > .syt-antd-collapse-item.syt-antd-collapse-no-arrow > .syt-antd-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}
