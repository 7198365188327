.loader {
  border: 4px solid #DFE2E7;
  border-radius: 50%;
  border-top: 4px solid #14803C;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}
.loader-wrapper{
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 45%;
  z-index: 9999999;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}