@import '~antd/lib/drawer/style/index';
@import '~antd/lib/tooltip/style/index';
@import '~antd/lib/progress/style/index';
@import '~antd/lib/skeleton/style/index';


.syt-antd-drawer-header {
  padding: 24px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0075em;
  border-bottom: none;
}

.syt-antd-drawer-body {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;
}

.syt-antd-drawer-footer {
  padding: 16px 24px;
  border-top: none;
}

.cw-get-started-text {
  margin-bottom: 10px;
}

.cw-get-started-header .cwsTypography {
  line-height: 22px;
}

.cw-get-started-header {
  width: fit-content;
}

.cw-get-started-content {
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
}

.cw-get-started-empty-content {
  margin-top: 150px;
  text-align: center;
}


.cwsTypography{

}
.cw-get-started-button {
    background: #5EBB7F;
    width: 352px;
    height: 105px;
    align-items: flex-start;
    padding-left: 24px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    margin-top: 17px;
    &:hover {
      background: #5cb27a;
    }
  }

  .cw-get-started-grower-icon {
    margin-top: -122px;
    float: right;
    margin-right: 20px;
  }

  .cw-get-started-wheat-icon {
    margin-top: -47px;
    float: right;
    margin-left: 80px;
  }

  .cw-get-whats-new-content {
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  .cw-quick-link-button {
    width: 100%;
    background: #FFFFFF;
    text-align: left;
    color: #0071CD;
    &:hover {
      background: #FFFFFF;
      color: #0071CD;
      text-decoration: underline;
    }
  }
  .cw-whats-new-button {
    background: #FFFFFF;
    width: 352px;
    height: 105px;
    align-items: flex-start;
    padding-left: 24px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    &:hover {
      background: #F3F4F6;
    }
  }
  .cw-whats-new-button .cw-whats-new-text{
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #363948;
    margin-top: 60px;
  }
  .cw-whats-new-girl-icon {
    margin-top: -122px;
    float: right;
    margin-right: 20px;
    position: relative;
    z-index: 2;
  }

  .cw-whats-new-grama-icon {
    margin-top: -47px;
    float: right;
    margin-left: 80px;
    position: absolute;
    right: 0px;
    bottom: -5px;
    z-index: 0;
  }