.cw-deck-of-apps-wrapper {
    width: 372px;
    padding: 18px 12px 0 12px;
}

.syt-antd-popover-arrow {
    display: none;
}

.syt-antd-popover-content {
    width: 372px;
}

.cw-deck-of-apps-icon {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    &:hover {
        background-color: #C2C7D0;
    }
}

.cw-dont-have-access-info-modal .syt-antd-modal-body{
    padding: 0px;
    padding-bottom: 26px;
    text-align: center;
    p.small {
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 0px;
        color: #696F88;
        font-weight: 400;
    }
    p.title {
        font-size: 20px;
        color: #14803C;
        font-weight: 600;
    }
    p.regular {
        font-size: 16px;
        color: #14151C;
        font-weight: 400;
    }
}

.cw-dont-have-access-info-modal .syt-antd-modal-body img,
.cw-dont-have-access-info-modal .syt-antd-modal-body svg{
    width: 100%;
}

.cw-collapse-menu {
    border: none;
}

.no-access-animation-block{
    position: relative;
    top: 0;
    left: 0;  
} 
.no-access-animation-block #access-animation {
    position: relative;
    top: 0;
    left: 0;
}
